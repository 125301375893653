import React, { useState, useEffect, useRef } from 'react';
import { styled } from '@mui/material/styles';
import CommonHeaderText from '@/components/packages/common-header-text';
import HorizontalScroll from '@/components/packages/horizontal-scroll';
import styledC from 'styled-components';

const PREFIX = 'HorizontalDateRange';

const classes = {
  nextIcon: `${PREFIX}-nextIcon`,
  prevIcon: `${PREFIX}-prevIcon`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(() => ({
  [`& .${classes.nextIcon}`]: {
    position: 'relative',
    right: '0px',
    zIndex: '1',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#fff',
    borderRadius: '50%',
    height: '23px',
    width: '23px',
    cursor: 'pointer',
    '& img': {
      marginTop: '3px!important',
    },
  },

  [`& .${classes.prevIcon}`]: {
    position: 'relative',
    left: '-10px',
    zIndex: '1',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#fff',
    borderRadius: '50%',
    height: '23px',
    width: '23px',
    cursor: 'pointer',
    '& img': {
      marginTop: '3px!important',
    },
  },
}));

export const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];
export const weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

const Button = styledC.button`
  color: #6a6a6a;
  width: 55px;
  border: 1px solid #6a6a6a66;
  cursor: pointer;
  height: 75px;
  margin: 0 12px 5px 0;
  display: inline-block;
  // padding: 5px 25px;
  max-width: 80px;
  text-align: center;
  border-radius: 10px;
  background-color: #ffffff;
  &:disabled {
    color: #333;
  }
`;

const ButtonToggle = styled(Button)`
  height: 85px;
`;

Date.prototype.addDays = function (days) {
  var dat = new Date(this.valueOf());
  dat.setDate(dat.getDate() + days);
  return dat;
};

function getDates(startDate, stopDate, monthsGet, weekdaysGet) {
  var dateArray = new Array();
  var currentDate = startDate;
  while (currentDate <= stopDate) {
    let date = {
      weekday: weekdaysGet[currentDate.getDay()],
      day: currentDate.getDate(),
      month: monthsGet[currentDate.getMonth()],
      date: currentDate.toLocaleDateString('en-US'),
    };
    if (weekdaysGet[currentDate.getDay()] !== 'Tue') {
      dateArray.push(date);
    }
    currentDate = currentDate.addDays(1);
  }
  return dateArray;
}

const HorizontalDateRange = props => {
  const { start_date, end_date, setDate } = props;

  const [dates, setDates] = useState([]);
  const startDateWithoutTime = new Date(start_date);
  const endDateWithoutTime = new Date(end_date);
  startDateWithoutTime.setHours(0, 0, 0, 0);
  endDateWithoutTime.setHours(0, 0, 0, 0);

  const componentRef = useRef();
  const loadNextDates = () => {
    componentRef.current.handleArrowClickRight();
  };
  const loadPrevDates = () => {
    componentRef.current.handleArrowClick();
  };

  const startTime = startDateWithoutTime.getTime();
  const endTime = endDateWithoutTime.getTime();
  useEffect(() => {
    var datesarr = getDates(
      startDateWithoutTime,
      endDateWithoutTime,
      months,
      weekdays
    );
    setDates(datesarr);
    return;
  }, [startTime, endTime]);

  const setActive = (e, date) => {
    setDate(date);
    const revised_dates = [...dates];
    for (let i = 0; i < revised_dates.length; i++) {
      revised_dates[i].active = revised_dates[i].date == date;
    }
    setDates(revised_dates);
  };

  return (
    (<Root>
      <div style={{display:"flex", alignItems:'center'}}>
      <div onClick={loadPrevDates} className={classes.prevIcon}>
        <img
          style={{
            objectFit: 'cover',
            objectPosition: '-318px 5px',
          }}
          src="/static/images/builder-page-image.svg"
          width={28}
          height={38}
          alt="next icon"
          title="next icon">
        </img>
      </div>
      <div style={{width: '100%',marginLeft:'-22px'}}>
        <HorizontalScroll
          componentRef={componentRef}
          dragging={true}
          data={
            dates &&
            dates.map((data, index) => {
              const { day, weekday, month, active, date } = data;
              return (
                <ButtonToggle
                  key={index}
                  value={date}
                  // active={active}
                  style={
                    active
                      ? {
                        border: '2px solid #0056B2',
                        color: '#171717',
                        height: ' 85px',
                        } : {
                          border: '1px solid #99a2ac',
                          color: '#6a6a6a',
                          height: ' 85px',
                        }
                  }
                  onClick={e => {
                    setActive(e, date);
                  }}>
                  <span
                    style={{
                      fontSize: '16px',
                      fontWeight: '400',
                      color: '#171717',
                      fontFamily: 'Open Sans',
                    }}>
                    {weekday}
                  </span>
                  <CommonHeaderText
                    text={day}
                    variant="h5"
                    style={{
                      fontSize: '18px',
                      fontWeight: '700',
                      fontFamily: 'Open Sans',
                      color: '#171717',
                    }}
                  />
                  <CommonHeaderText
                    text={month}
                    variant="h5"
                    style={{
                      fontSize: '16px',
                      fontWeight: '400',
                      fontFamily: 'Open Sans',
                      color: '#171717',
                    }}
                  />
                </ButtonToggle>
              );
            })
          }></HorizontalScroll>
      </div>
      <div onClick={loadNextDates} className={classes.nextIcon}>
        <img
          style={{
            objectFit: 'cover',
            objectPosition: '-349px 5px',
          }}
          src="/static/images/builder-page-image.svg"
          width={28}
          height={38}
          alt="next icon"
          title="next icon">

        </img>
      </div>
      </div>
    </Root>)
  );
};

export default HorizontalDateRange;
