import React from "react";
import { styled } from "@mui/material/styles";
import { Box, Button } from "@mui/material";
import ClassNames from "classnames";

const PREFIX = "CommonTabs";

const classes = {
  root: `${PREFIX}-root`,
  commTabs: `${PREFIX}-commTabs`,
  tabButton: `${PREFIX}-tabButton`,
  activeTab: `${PREFIX}-activeTab`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.root}`]: {
    padding: "0px",
    [theme.breakpoints.down('md')]: {
      backgroundColor: '#B9B9B9',
      borderRadius: '12px',
      borderTopLeftRadius: '12px',
      borderTopRightRadius: '12px',
    },
  },
  [`& .${classes.commTabs}`]: {
    display: "flex",
    borderRadius: "12px",
    overflow: "hidden",
    gap: "10px",
    [theme.breakpoints.down('md')]: {
      padding: '10px 10px 0px',
    },
  },
  [`& .${classes.tabButton}`]: {
    flex: 1,
    padding: "20px 0px",
    border: "1px solid #D3E0ED",
    textTransform: "capitalize",
    color: "#171717",
    fontSize: "16px",
    fontWeight: 700,
    backgroundColor: "rgb(243, 243, 255)",
    cursor: "pointer",
    transition: "background-color 0.3s",
    borderRadius: '12px',
    [theme.breakpoints.down('sm')]: {
      fontWeight: '700',
      fontSize: '16px',
      lineHeight: '27px',
      letterSpacing: '0px',
      height: '54px',
    },
  },
  [`& .${classes.activeTab}`]: {
    backgroundColor: "#0B56B2",
    color: "#fff",
  },
}));

function CommonTabs({ list, root_class, active_index = 0 }) {
  const [tabValue, setTabValue] = React.useState(active_index);

  return (
    <Root>
      <div className={ClassNames([classes.root, root_class])}>
        {/* Accessible Tablist */}
        <Box className={classes.commTabs} role="tablist">
          {list?.map((data, index) => (
            <Button
              key={index}
              className={ClassNames(classes.tabButton, {
                [classes.activeTab]: tabValue === index,
              })}
              onClick={() => setTabValue(index)}
              role="tab"
              aria-selected={tabValue === index}
              id={`tab-${index}`}
              aria-controls={`tabpanel-${index}`}
            >
              {data.label}
            </Button>
          ))}
        </Box>

        {/* Accessible Tab Panels */}
        {list?.map((data, index) => tabValue === index ? (
          <Box
            style={{
              padding: '10px 0px 0px'
            }}
            key={index}
            // hidden={tabValue !== index}
            role="tabpanel"
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
          >
            {data.value}
          </Box>
        ) : null)}
      </div>
    </Root>
  );
}

export default CommonTabs;
